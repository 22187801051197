<template>
	<div class="parkSurplus">
    <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 30px">
      <el-breadcrumb-item :to="{ path: '/parkLocation' }">园区选址</el-breadcrumb-item>
      <el-breadcrumb-item>地址管理</el-breadcrumb-item>
    </el-breadcrumb>		
		<div class="top">
			<div style="display: flex;">
				<el-input v-model="keyword" size="small" style="width: 399px;" placeholder="输入用户名称、公司、品牌进行搜索"></el-input>
				<el-button type="primary" icon="el-icon-search" size="small" style="margin-left:5px;" @click="search">搜索</el-button>
			</div>
			<div>
				<el-button type="primary" size="small" @click="parkDialog">添加地址</el-button>
				<el-button type="primary" size="small" @click="addFiles">批量上传</el-button>
			</div>
		</div>
		<div class="table">
			<el-table :data="managersList" border style="width: 100%">
				<el-table-column prop="address" align="center" label="详细地址"></el-table-column>
				<el-table-column align="center" label="使用状态" width="180">
					<template slot-scope="scope">
						<el-select v-model="scope.row.use_status" placeholder="请选择" @change="usageStatus(scope.row)">
							<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</template>          
        </el-table-column>
				<el-table-column prop="use_times" align="center" label="使用次数" width="180"></el-table-column>
				<el-table-column label="操作" align="center" width="240">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" v-if="scope.row.status == 1" @click="upStatusChange(scope.row)">下架</el-button>
						<el-button type="primary" size="mini" v-else @click="upStatusChange(scope.row)">上架</el-button>
						<el-button type="primary" size="mini" @click="parkDialog(scope.row)">编辑</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pages">
				<el-pagination background @current-change="handleCurrentChange" :current-page="1" :page-count="pageCount" layout="total,prev,pager,next,jumper"
				 :total="total">
				</el-pagination>
			</div>
		</div>
    <!-- 批量添加文件 -->
    <importRegistration :display="display" @cancel="cancel" />
		<!-- 添加&编辑地址 -->
		<el-dialog :title="rowData?'添加地址':'编辑地址'" :visible.sync="dialogVisible" >
			<el-input v-model="addressInput" placeholder="请输入地址"></el-input>
			<div style="display:flex;justify-content: center;margin-top: 10px">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="upParkLocation">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
  import importRegistration from "@/components/importRegistration";

	export default {
		name: 'parkSurplus',
		data() {
			return {
				id: '', //园区id
				keyword: '', //关键词
				managersList: [],
				total: 0,
				page: 1,
				limit: 10,
				pageCount: Math.ceil(this.total / 10),
				display: false,
				options: [
					{label: '使用中', value: 1},
					{label: '锁定中', value: 2},
					{label: '回收中', value: 3},
					{label: '未使用', value: 4},
				],
				dialogVisible: false, // 弹窗
				rowData: false,
				addressInput: ''
			}
		},
		components: {
			importRegistration
		},    
		mounted() {
			this.getParkLocation();
		},
		methods: {
			// 获取地址数据
			getParkLocation() {
				this.$http.get(this.URL.adminUrl.siteAddress, {
					params: {
						site_selection_id: this.id,
						keyword: this.keyword
					}					
				}).then((res) => {
					// console.log(res.data.data)
					this.managersList = res.data.data.data;
					this.total = res.data.data.meta.total;
					this.dialogVisible = false
				})
			},
			// 搜索
			search() {
				this.page = 1;
				this.getParkLocation();
			},
			// 分页
			handleCurrentChange(val) {
				this.page = val;
				this.getParkLocation();
			},
			// 弹窗
			parkDialog(row) {
				this.rowData = row ? row : []
				this.dialogVisible = true
			},			
			// 添加&编辑提交地址
			upParkLocation() {
				if(this.rowData.address){ //编辑
					this.$http.post(this.URL.adminUrl.addressChange + this.rowData.id, {
						address: this.addressInput
					}).then((res) => {
						this.getParkLocation();
					})
				}else{ //新建
					this.$http.post(this.URL.adminUrl.siteAddress, {
						site_selection_id: this.id,
						address: this.addressInput
					}).then((res) => {
						this.getParkLocation();
					})
				}
			},
			// 上下架
			upStatusChange(row) {
				// console.log(row);
				this.$http.post(this.URL.adminUrl.addressChange + row.id, {
					status: row.status == 1? 2 : 1
				}).then((res) => {
					this.getParkLocation();
				})
			},
			// 使用状态
			usageStatus(row) {
				// console.log(row)
				this.$http.post(this.URL.adminUrl.addressChange + row.id, {
					use_status: row.use_status
				}).then((res) => {
					this.getParkLocation();;
				})
			},
			//添加文件
			addFiles() {
				this.display = true;
			},      
			// 控制上传弹窗
			uploadDisplayChange(msg) {
				this.uploadDisplay = msg;
			},
			cancel() {
				this.display = false;
				this.getParkLocation();
			},			
		},
		created() {
			// console.log(this.$route.params.data)
			this.id = this.$route.params.data.id
		}
	}
</script>
<style scoped>
	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.table {
		margin-top: 30px;
	}

	.pages {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 50px 0;
	}
</style>
